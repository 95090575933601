import { post } from '@/utils/request'

// 店铺列表
export function shopList(data) {
    return post('/admin/merchant/listMerchant', data)
}
// 店铺商品分类
export function getShopClass(data) {
    return post('/admin/inventory/getShopClass', data)
}
//根据店铺id获取商品列表 

export function getGoodsList(data) {
    return post('/psi/coupon/goodsList', data)
}
//新增优惠活动
export function addCoupon(data) {
    return post('/psi/coupon/add', data)
}
//优惠活动列表
export function couponList(data) {
    return post('/psi/coupon/list', data)
}
//优惠活动详情
export function getInfo(data) {
    return post('/psi/coupon/info', data)
}
//编辑优惠活动
export function editCoupon(data) {
    return post('/psi/coupon/edit', data)
}
// 删除优惠活动
export function delCoupon(data) {
    return post('/psi/coupon/del', data)
}
