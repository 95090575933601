<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>优惠活动管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="4">
              <el-button type="primary" @click="pAdd()">添加优惠活动</el-button>
            </el-col>
            <el-col :span="4">
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="coupon_name" label="优惠活动名称">
            </el-table-column>
            <el-table-column label="优惠活动类型">
              <template v-slot="scope">
                <el-tag type="warning" v-if="scope.row.type == 0"
                  >优惠券</el-tag
                >
                <el-tag type="success" v-if="scope.row.type == 1"
                  >优惠活动</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column prop="shop_name" label="店铺"> </el-table-column>
            <el-table-column prop="use_start_time" label="开始时间">
            </el-table-column>
            <el-table-column prop="use_deadline" label="结束时间">
            </el-table-column>
            <el-table-column
              prop="img"
              label="优惠券图片"
              :label-width="formLabelWidth"
            >
              <template v-slot="scope">
                <img
                  style="cursor: pointer;"
                  width="100"
                  :src="scope.row.img"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="coupon_name"
          label="优惠活动名称"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入优惠活动名称"
            @change="handleChange"
            v-model="form.coupon_name"
          ></el-input>
        </el-form-item>
        <el-form-item prop="type" label="类型" :label-width="formLabelWidth">
          <el-radio v-model="form.type" :label="0">优惠券</el-radio>
          <el-radio v-model="form.type" :label="1">优惠活动</el-radio>
        </el-form-item>
        <el-form-item
          prop="use_type"
          label="使用范围"
          :label-width="formLabelWidth"
        >
          <el-select
            @change="handleUsageChange"
            v-model="form.use_type"
            style="width: 100%"
            placeholder="请选择使用范围"
            clearable
          >
            <el-option
              v-for="item in usage"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="form.use_type === 2"
          :label-width="formLabelWidth"
          prop="member"
          label="会员"
        >
          <el-radio v-model="form.member" :label="1">是</el-radio>
          <el-radio v-model="form.member" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item
          v-if="form.use_type === 2 && form.member === 1"
          prop="level"
          :label-width="formLabelWidth"
          label="会员等级"
        >
          <el-radio-group v-model="form.level" size="small">
            <el-radio-button label="1"></el-radio-button>
            <el-radio-button label="2"></el-radio-button>
            <el-radio-button label="3"></el-radio-button>
            <el-radio-button label="4"></el-radio-button>
            <el-radio-button label="5"></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="店铺"
          prop="shop_id"
          v-if="
            form.use_type === 1 || form.use_type === 0 || form.use_type === 2
          "
          :label-width="formLabelWidth"
        >
          <el-select
            @change="handleStoreChange"
            v-model="form.shop_id"
            style="width: 100%"
            placeholder="请选择店铺"
            clearable
          >
            <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="分类"
          prop="class_id"
          v-if="form.use_type === 2 && form.shop_id"
          :label-width="formLabelWidth"
        >
          <el-cascader
            style="width: 100%"
            placeholder="请选择分类"
            :options="typeList"
            :props="optionProps"
            v-model="form.class_id"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          label="商品"
          v-if="form.use_type === 1 && form.shop_id"
          :label-width="formLabelWidth"
        >
          <el-cascader
            style="width: 100%"
            placeholder="请选择分类下的商品"
            :options="goodsList"
            :props="optionProps"
            v-model="form.goods_id"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          label="发放类型"
          prop="distribute_type"
          :label-width="formLabelWidth"
        >
          <el-select
            @change="handleChange"
            v-model="form.distribute_type"
            style="width: 100%"
            placeholder="请选择发放类型"
            clearable
          >
            <el-option
              v-for="item in discount_type"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.distribute_type === 0"
          prop="spend_threshold"
          label="消费金额门槛"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入消费金额门槛"
            type="number"
            min="0"
            @change="handleChange"
            v-model="form.spend_threshold"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="form.distribute_type === 0"
          prop="preferential_price"
          label="优惠金额"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入优惠金额"
            type="number"
            min="0"
            @change="handleChange"
            v-model="form.preferential_price"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.distribute_type === 1 || form.distribute_type === 3"
          prop="num_threshold"
          label="消费数量门槛"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入消费数量门槛"
            type="number"
            min="0"
            @change="handleChange"
            v-model="form.num_threshold"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.shop_id"
          prop="preferential_goods_id"
          label="赠送商品"
          :label-width="formLabelWidth"
        >
          <el-cascader
            style="width: 100%"
            placeholder="请选择分类下的商品"
            :options="goodsList"
            :props="optionProps1"
            v-model="form.preferential_goods_id"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item
          v-if="form.distribute_type === 1"
          prop="preferential_num"
          label="优惠数量"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入优惠数量"
            type="number"
            min="0"
            @change="handleChange"
            v-model="form.preferential_num"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.distribute_type === 2 || form.distribute_type === 3"
          prop="discount"
          label="折扣"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入折扣"
            type="number"
            min="0"
            @change="handleChange"
            v-model="form.discount"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="gift" label="赠品" :label-width="formLabelWidth">
          <el-input
            placeholder="请输入赠品"
            min="0"
            @change="handleChange"
            v-model="form.gift"
          ></el-input>
        </el-form-item> -->
        <el-form-item prop="gift1" :label-width="formLabelWidth" label="赠品">
          <div
            style="display:inline-block"
            v-for="(item, index) in form.gift1"
            :key="index"
          >
            <el-input
              @input="handleIptChange()"
              v-model="item.name"
              placeholder="赠品名"
              style="width:100px"
            ></el-input>
            <el-input
              @input="handleIptChange()"
              v-model="item.amount"
              type="number"
              min="1"
              placeholder="数量"
              style="width:80px;margin:0 5px"
            ></el-input>
          </div>
          <span @click="addNewInput()" style="color:#409eff;cursor: pointer"
            >增加
          </span>
          <span @click="delLastInput()" style="color:#f56c6c;cursor: pointer"
            >删除</span
          >
        </el-form-item>

        <el-form-item
          prop="distribute_num"
          label="发放数量"
          :label-width="formLabelWidth"
        >
          <el-input
            placeholder="请输入发放数量"
            disabled
            type="number"
            min="0"
            @change="handleChange"
            v-model="form.distribute_num"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="use_start_time"
          label="活动开始时间"
          :label-width="formLabelWidth"
        >
          <el-date-picker
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.use_start_time"
            type="datetime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          prop="use_deadline"
          label="活动结束时间"
          :label-width="formLabelWidth"
        >
          <el-date-picker
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.use_deadline"
            type="datetime"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
import lodash from 'lodash'

import {
  shopList,
  getShopClass,
  getGoodsList,
  addCoupon,
  couponList,
  getInfo,
  editCoupon,
  delCoupon,
} from '@/api/psi/coupon.js'
export default {
  data() {
    var validateClass = (rule, value, callback) => {
      if (this.form.use_type === 2) {
        if (this.form.class_id === '' || this.form.class_id.length === 0) {
          callback(new Error('分类不能为空'))
        }
      } else {
        callback()
      }
    }
    var validateGoods = (rule, value, callback) => {
      if (this.form.use_type === 1) {
        if (this.form.goods_id === '' || this.form.goods_id.length === 0) {
          callback(new Error('商品不能为空'))
        }
      } else {
        callback()
      }
    }
    return {
      goodsList: [],
      typeList: [],
      dialogLog: {
        state: false,
      },
      discount_type: [
        {
          name: '满减优惠',
          id: 0,
        },
        {
          name: '满送优惠',
          id: 1,
        },
        {
          name: '折扣',
          id: 2,
        },
        {
          name: '第二份半价',
          id: 3,
        },
      ],
      usage: [
        {
          name: '全店通用',
          id: 0,
        },

        {
          name: '指定分类',
          id: 2,
        },
        {
          name: '指定商品',
          id: 1,
        },
      ],
      shopList: [],
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        coupon_name: {
          required: true,
          message: '请输入优惠活动名称',
          trigger: 'blur',
        },
        distribute_type: {
          required: true,
          message: '请选择优惠券发放类型',
          trigger: 'blur',
        },
        type: {
          required: true,
          message: '请选择活动类型',
          trigger: 'blur',
        },
        use_type: {
          required: true,
          message: '请选择使用范围',
          trigger: 'blur',
        },
        shop_id: {
          required: true,
          message: '请选择店铺',
          trigger: 'blur',
        },
        distribute_num: {
          required: true,
          message: '请输入发放数量',
          trigger: 'blur',
        },
        use_start_time: {
          required: true,
          message: '请选择活动开始时间',
        },
        use_deadline: {
          required: true,
          message: '请选择活动结束时间',
        },
        class_id: [
          {
            validator: validateClass,
            trigger: 'change',
          },
        ],
        goods_id: [
          {
            validator: validateGoods,
            trigger: 'change',
          },
        ],
      },
      title: '添加优惠活动',
      addOrEdit: 1,
      formLabelWidth: '120px',
      optionsTask: [],
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'son',
        multiple: true,
      },
      optionProps1: {
        value: 'id',
        label: 'name',
        children: 'son',
        multiple: false,
      },
      form: {
        // sort: '',
        coupon_name: '',
        distribute_type: '',
        use_type: '',
        shop_id: '',
        type: 0,
        class_id: [],
        goods_id: [],
        spend_threshold: '',
        num_threshold: '',
        preferential_price: '',
        preferential_goods_id: '',
        preferential_num: '',
        discount: '',
        gift: [],
        gift1: [],
        distribute_num: 0,
        use_start_time: '',
        use_deadline: '',
        member: '',
        level: '',
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
    this.getOptions()
  },
  methods: {
    addNewInput(row) {
      // this.commodityForm.meal.push({
      //     name:'',
      //     amount:''
      // })
      this.$set(this.form.gift1, this.form.gift1.length, {
        name: '',
        amount: 1,
      })
      this.$forceUpdate()
      // console.log(11111111)
    },
    delLastInput() {
      if (this.form.gift1.length !== 0) {
        this.form.gift1.pop()
      }
    },
    handleIptChange() {
      this.$forceUpdate()
    },
    async handleStoreChange() {
      this.isEdit = true

      if (this.form.use_type === 2) {
        const { data: res } = await getShopClass({ app_id: this.form.shop_id })
        console.log(res)
        if (res.code === 1) {
          this.typeList = res.data
        } else {
          this.$message.error(res.message)
        }
      }
      //  if (this.form.use_type === 1) {
      const { data: res } = await getGoodsList({ shop_id: this.form.shop_id })
      console.log(res)
      if (res.code === 1) {
        this.goodsList = res.data
      } else {
        this.$message.error(res.message)
      }
      // }
    },
    handleClassChange() {
      this.isEdit = true
    },
    handleUsageChange() {
      this.isEdit = true
      //   this.handleStoreChange()
      if (this.form.use_type === 1) {
        // 指定商品
        if (this.form.shop_id !== '') {
          this.handleStoreChange()
        }
      } else if (this.form.use_type === 2) {
        // 指定分类
        if (this.form.shop_id !== '') {
          this.handleStoreChange()
        }
      }
    },
    async getOptions() {
      const { data: res } = await shopList()
      console.log(res)

      this.shopList = res.data
    },
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await couponList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加优惠活动'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        coupon_name: '',
        distribute_type: '',
        // sort: '',
        use_type: '',
        shop_id: '',
        class_id: [],
        type: 0,
        goods_id: [],
        spend_threshold: '',
        num_threshold: '',
        preferential_price: '',
        preferential_goods_id: '',
        preferential_num: '',
        discount: '',
        gift1: [],
        gift: [],
        distribute_num: 0,
        use_start_time: '',
        use_deadline: '',
        member: '',
        level: '',
      }
      setTimeout(() => {
        this.$refs.formRef.clearValidate()
      }, 30)
      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      console.log(row)
      this.dialogVisible = true
      this.title = '编辑优惠活动'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        coupon_activity_id: row.coupon_activity_id,
      })
      let re = res.data
      // this.form = res.data
      this.form.coupon_activity_id = re.coupon_activity_id
      this.form.coupon_name = re.coupon_name
      this.form.distribute_type = re.distribute_type
      this.form.use_type = re.use_type
      this.form.member = re.member
      this.form.level = re.level
      this.form.type = re.type
      //   this.form.sort = re.sort
      this.form.shop_id = re.shop_id
      this.form.use_deadline = re.use_deadline
      this.form.use_start_time = re.use_start_time
      //   this.form.gift = re.gift
      if (re.gift === '') {
        // 赠品为空
        this.form.gift1 = []
      } else {
        // 赠品不为空
        re.gift.forEach((item) => {
          let arr = item.split('_')
          this.form.gift1.push({
            name: arr[0],
            amount: arr[1],
          })
        })
      }
      this.form.status = re.status
      this.form.discount = re.discount
      this.form.preferential_num = re.preferential_num
      this.form.preferential_price = re.preferential_price
      this.form.spend_threshold = re.spend_threshold
      this.form.num_threshold = re.num_threshold
      this.handleStoreChange().then(() => {
        console.log(this.typeList)
        //console.log(this.cascaderRepaint(19, this.goodsList))

        if (re.class_id && re.class_id.length > 0) {
          let ids = []
          for (let i = 0; i < re.class_id.length; i++) {
            ids.push(Number(re.class_id[i]))
          }
          let newArr = []
          ids.forEach((id) => {
            newArr.push(this.cascaderRepaint(id, this.typeList))
          })
          this.form.class_id = newArr
        }

        if (re.goods_id && re.goods_id.length > 0) {
          let ids = []
          // console.log(re.goods_id)
          for (let i = 0; i < re.goods_id.length; i++) {
            ids.push(Number(re.goods_id[i]))
          }
          let newArr = []
          ids.forEach((id) => {
            // console.log(id)
            newArr.push(this.cascaderRepaint(id, this.goodsList))
          })
          this.form.goods_id = newArr
        }

        if (re.preferential_goods_id && re.preferential_goods_id.length !== 0) {
          let ids = []
          // console.log(re.preferential_goods_id)
          let arr = [re.preferential_goods_id]
          console.log(arr)
          //   re.preferential_goods_id = [re.preferential_goods_id]
          for (let i = 0; i < arr.length; i++) {
            ids.push(Number(arr[i]))
          }
          console.log(ids)
          let newArr = []
          ids.forEach((id) => {
            console.log(id)
            newArr = this.cascaderRepaint(id, this.goodsList)
          })
          console.log(newArr)
          this.form.preferential_goods_id = newArr
        }
      })
    },
    cascaderRepaint(key, treeData) {
      // console.log(treeData)
      let arr = [] // 在递归时操作的数组
      let returnArr = [] // 存放结果的数组
      let depth = 0 // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1) //将目前匹配的数组，截断并保存到结果数组，
            break
          } else {
            if (childrenData[j].son) {
              depth++
              childrenEach(childrenData[j].son, depth)
            }
          }
        }
        return returnArr
      }
      return childrenEach(treeData, depth)
    },
    changeCascader(key, arrData) {
      let arr = []
      let returnArr = [] // 存放结果的数组
      let depth = 0 // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1) //将目前匹配的数组，截断并保存到结果数组，
            break
          } else {
            if (childrenData[j].son) {
              depth++
              childrenEach(childrenData[j].son, depth)
            }
          }
        }
        return returnArr
      }
      return childrenEach(arrData, depth)
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delCoupon({
            coupon_activity_id: row.coupon_activity_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    async confirmAE(e) {
      let query = {}
      query = lodash.cloneDeep(this.form)
      if (this.addOrEdit === 1) {
        // 添加
        if (query.gift1.length !== 0) {
          query.gift1.forEach((ele) => {
            const str = ele.name + '_' + ele.amount
            query.gift.push(str)
          })
        }
        console.log(query.gift)
        delete query['gift1']
        if (this.form.use_type === 1) {
          let commo_ids = []
          //指定商品
          this.form.goods_id.forEach((item) => {
            let length = item.length
            let id = item[length - 1]
            commo_ids.push(id)
          })

          query.goods_id = commo_ids
          query.class_id = []
        } else if (this.form.use_type === 0) {
          // 全店通用
          query.class_id = []
          query.goods_id = []
        } else if (this.form.use_type === 2) {
          //指定分类
          let class_ids = []
          this.form.class_id.forEach((item) => {
            let length = item.length
            let id = item[length - 1]
            class_ids.push(id)
          })

          query.goods_id = []
          query.class_id = class_ids
          console.log(class_ids)
        }
        if (this.form.distribute_type === 1) {
          // 满送优惠
          query.spend_threshold = ''
          query.preferential_price = ''
          query.discount = ''
        } else if (this.form.distribute_type === 0) {
          // 满减优惠
          query.num_threshold = ''
          query.preferential_num = ''
          query.discount = ''
        } else if (this.form.distribute_type === 2) {
          // 折扣
          query.spend_threshold = ''
          query.preferential_price = ''
          query.num_threshold = ''
          query.preferential_num = ''
        }
        if (query.preferential_goods_id.length !== 0) {
          query.preferential_goods_id =
            query.preferential_goods_id[query.preferential_goods_id.length - 1]
        } else {
          query.preferential_goods_id = 0
        }
        let formData = new FormData()
        for (let key in query) {
          formData.append(key, query[key])
        }
        console.log(query)
        const { data: res } = await addCoupon(formData)
        if (res.code === 1) {
          this.$message.success('添加成功')
          this.getList()
          this.cancelAE()
        } else {
          this.$message.error(res.message)
        }
      } else {
        // 编辑

        if (this.isEdit === true) {
          if (query.gift1.length !== 0) {
            query.gift1.forEach((ele) => {
              const str = ele.name + '_' + ele.amount
              query.gift.push(str)
            })
          }
          console.log(query.gift)
          delete query['gift1']
          if (this.form.use_type === 1) {
            let commo_ids = []
            //指定商品
            this.form.goods_id.forEach((item) => {
              let length = item.length
              let id = item[length - 1]
              commo_ids.push(id)
            })

            query.goods_id = commo_ids
            query.class_id = []
          } else if (this.form.use_type === 0) {
            // 全店通用
            query.class_id = []
            query.goods_id = []
          } else if (this.form.use_type === 2) {
            //指定分类
            let class_ids = []
            this.form.class_id.forEach((item) => {
              let length = item.length
              let id = item[length - 1]
              class_ids.push(id)
            })

            query.goods_id = []
            query.class_id = class_ids
            console.log(class_ids)
          }
          if (this.form.distribute_type === 1) {
            // 满送优惠
            query.spend_threshold = ''
            query.preferential_price = ''
            query.discount = ''
          } else if (this.form.distribute_type === 0) {
            // 满减优惠
            query.num_threshold = ''
            query.preferential_num = ''
            query.discount = ''
          } else if (this.form.distribute_type === 2) {
            // 折扣
            query.spend_threshold = ''
            query.preferential_price = ''
            query.num_threshold = ''
            query.preferential_num = ''
          }
          if (query.preferential_goods_id.length !== 0) {
            query.preferential_goods_id =
              query.preferential_goods_id[
                query.preferential_goods_id.length - 1
              ]
          } else {
            query.preferential_goods_id = 0
          }
          let formData = new FormData()
          for (let key in query) {
            formData.append(key, query[key])
          }
          const { data: res } = await editCoupon(formData)
          if (res.code === 1) {
            this.$message.success('编辑成功')
            this.cancelAE()
            this.getList()
          } else {
            this.$message.error(res.message)
          }
        } else {
          this.$message.info('没有修改任何数据')
        }
      }
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
/* .content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
} */
</style>
